import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="activity-selection"
export default class extends Controller {
  static targets = ["checkbox", "submitButton"];

  connect() {
    this.updateButtonState();
  }

  toggleSelection() {
    this.updateButtonState();
  }

  updateButtonState() {
    const checkedBoxes = this.checkboxTargets.filter((cb) => cb.checked);
    this.submitButtonTarget.disabled = checkedBoxes.length === 0;
  }

  addSelectedActivities(event) {
    console.log("addSelectedActivities");
    event.preventDefault();
    const form = event.target.closest("form");

    this.checkboxTargets.forEach((checkbox) => {
      if (checkbox.checked) {
        const input = document.createElement("input");
        input.type = "hidden";
        input.name = "activity_ids[]";
        input.value = checkbox.value;
        form.appendChild(input);
      }
    });

    form.submit();
  }
}
