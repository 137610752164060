import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="faceted-search"
export default class extends Controller {
  static targets = ["form"];

  connect() {
    this.formTarget.addEventListener("change", this.submit.bind(this));
  }

  submit(event) {
    if (
      event.target.type === "checkbox" ||
      event.target.type === "select-one"
    ) {
      this.formTarget.requestSubmit();
    }
  }
}
