import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="scroll-padding"
export default class extends Controller {
  static targets = ["content"];

  connect() {
    this.ticking = false;
    window.addEventListener("scroll", this.handleScroll.bind(this));
  }

  disconnect() {
    window.removeEventListener("scroll", this.handleScroll.bind(this));
  }

  handleScroll() {
    if (!this.ticking) {
      window.requestAnimationFrame(() => {
        this.updatePadding();
        this.ticking = false;
      });
      this.ticking = true;
    }
  }

  updatePadding() {
    if (window.scrollY > 0) {
      this.contentTarget.classList.add("pt-20");
    } else {
      this.contentTarget.classList.remove("pt-20");
    }
  }
}
