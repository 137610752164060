import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="form-submit-spinner"
export default class extends Controller {
  static targets = ["submitButton", "spinner"];

  connect() {
    this.spinner.classList.add("hidden");
  }

  submit(event) {
    event.preventDefault();
    this.disableSubmitButton();
    this.showSpinner();
    this.element.requestSubmit();
  }

  disableSubmitButton() {
    this.submitButtonTarget.disabled = true;
  }

  showSpinner() {
    this.spinnerTarget.classList.remove("hidden");
  }
}
