import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="dropdown"
export default class extends Controller {
  static targets = ["menu", "chevronDown", "chevronUp"];

  toggle() {
    this.menuTarget.classList.toggle("hidden");
    this.chevronDownTarget.classList.toggle("hidden");
    this.chevronUpTarget.classList.toggle("hidden");
  }

  hide(event) {
    if (!this.element.contains(event.target)) {
      this.menuTarget.classList.add("hidden");
      this.chevronDownTarget.classList.remove("hidden");
      this.chevronUpTarget.classList.add("hidden");
    }
  }

  connect() {
    document.addEventListener("click", this.hide.bind(this));
  }

  disconnect() {
    document.removeEventListener("click", this.hide.bind(this));
  }
}
