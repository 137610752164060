import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="faceted-filter"
export default class extends Controller {
  static targets = ["facetGroup", "facetItem", "moreText", "lessText"];

  toggleMore(event) {
    event.preventDefault();
    const facetGroup = event.target.closest(
      "[data-faceted-filter-target='facetGroup']",
    );
    const hiddenItems = facetGroup.querySelectorAll(
      "[data-faceted-filter-target='facetItem'].hidden",
    );

    hiddenItems.forEach((item) => item.classList.toggle("hidden"));

    this.moreTextTarget.classList.toggle("hidden");
    this.lessTextTarget.classList.toggle("hidden");
  }
}
