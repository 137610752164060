import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="toggle"
export default class extends Controller {
  static targets = ["content", "button"];
  static values = {
    showText: String,
    hideText: String,
  };

  connect() {
    this.updateButtonText();
  }

  toggle() {
    this.contentTarget.classList.toggle("hidden");
    this.updateButtonText();
  }

  updateButtonText() {
    const isHidden = this.contentTarget.classList.contains("hidden");
    this.buttonTarget.textContent = isHidden
      ? this.showTextValue
      : this.hideTextValue;
  }
}
