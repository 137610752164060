import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="location-selection"
export default class extends Controller {
  static targets = [
    "checkbox",
    "sum",
    "subscriptionOptions",
    "monthlyPrice",
    "yearlyPrice",
    "yearlyTotal",
    "monthlyPriceExVAT",
    "yearlyPriceExVAT",
    "yearlyTotalExVAT",
    "yearlySavings",
    "form",
    "planInput",
  ];

  connect() {
    this.updateSum();
  }

  updateSum() {
    const totalPlaces = this.checkboxTargets
      .filter((checkbox) => checkbox.checked)
      .reduce(
        (sum, checkbox) => sum + parseInt(checkbox.dataset.numberOfPlaces, 10),
        0,
      );

    this.sumTarget.textContent = totalPlaces;
    this.toggleSubscriptionOptions(totalPlaces);
    if (totalPlaces > 0) {
      this.fetchPrices(totalPlaces);
    }
  }

  toggleSubscriptionOptions(totalPlaces) {
    if (totalPlaces > 0) {
      this.subscriptionOptionsTarget.classList.remove("hidden");
    } else {
      this.subscriptionOptionsTarget.classList.add("hidden");
    }
  }

  async fetchPrices(totalPlaces) {
    try {
      const response = await fetch(`/prices?units=${totalPlaces}`);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const prices = await response.json();
      this.updatePrices(prices);
    } catch (error) {
      console.error("Error fetching prices:", error);
    }
  }

  updatePrices(prices) {
    this.monthlyPriceTarget.textContent =
      prices.per_month_price_monthly.incl_vat.toFixed(2);
    this.yearlyPriceTarget.textContent =
      prices.per_month_price_yearly.incl_vat.toFixed(2);
    this.yearlyTotalTarget.textContent =
      prices.per_year_total_price.incl_vat.toFixed(2);

    this.monthlyPriceExVATTarget.textContent =
      prices.per_month_price_monthly.excl_vat.toFixed(2);
    this.yearlyPriceExVATTarget.textContent =
      prices.per_month_price_yearly.excl_vat.toFixed(2);
    this.yearlyTotalExVATTarget.textContent =
      prices.per_year_total_price.excl_vat.toFixed(2);

    // Calculate and display savings
    const monthlySavings =
      prices.per_month_total_year_amount.incl_vat -
      prices.per_year_total_price.incl_vat;
    const savingsPercentage = (
      (monthlySavings / prices.per_month_total_year_amount.incl_vat) *
      100
    ).toFixed(2);
    this.yearlySavingsTarget.textContent = `Bespaar ${savingsPercentage}% met jaarlijkse facturering`;
  }

  selectPlan(event) {
    event.preventDefault();
    const plan = event.currentTarget.dataset.plan;
    this.planInputTarget.value = plan;

    const selectedIds = this.checkboxTargets
      .filter((checkbox) => checkbox.checked)
      .map((checkbox) => checkbox.value);

    if (selectedIds.length === 0) {
      alert("Please select at least one location.");
      return;
    }

    this.formTarget.requestSubmit();
  }
}
