import { Controller } from "@hotwired/stimulus";
import { Sortable } from "sortablejs";
import { put } from "@rails/request.js";

// Connects to data-controller="sortable"
export default class extends Controller {
  static targets = ["element"];

  connect() {
    this.sortable = Sortable.create(this.element, {
      animation: 350,
      ghostClass: "bg-gray-200",
      onEnd: this.updatePosition.bind(this),
      handle: "[data-sortable-handle]",
    });
  }

  disconnect() {
    this.sortable.destroy();
  }

  async updatePosition(event) {
    const response = await put("/admin/positions", {
      body: JSON.stringify({
        sgid: this.elementTarget.dataset["sgid"],
        position: event.newIndex + 1,
      }),
    });
    if (response.ok) {
      console.log("Position updated");
    } else {
      console.log("Position not updated");
    }
  }
}
