import Flatpickr from "stimulus-flatpickr";
import { Dutch } from "flatpickr/dist/l10n/nl.js";

// Connects to data-controller="flatpickr"
// Creates a new Stimulus controller by extending stimulus-flatpickr wrapper controller
export default class extends Flatpickr {
  static values = {
    appendToDialog: { type: Boolean, default: false },
  };

  initialize() {
    this.config = {
      locale: Dutch,
      altInput: true,
      weekNumbers: true,
      animate: false,
      dateFormat: "Y-m-d",
    };

    if (this.appendToDialogValue) {
      this.config.appendTo = document.querySelector("dialog");
    }
  }
}
