import { Controller } from "@hotwired/stimulus";
import { DirectUpload } from "@rails/activestorage";

// Connects to data-controller="upload"
export default class extends Controller {
  static targets = [
    "input",
    "spinnerContainer",
    "progressContainer",
    "progressBar",
    "progressText",
    "fileName",
  ];

  connect() {
    this.inputTarget.addEventListener(
      "change",
      this.handleFileSelect.bind(this),
    );
  }

  disconnect() {
    this.inputTarget.removeEventListener(
      "change",
      this.handleFileSelect.bind(this),
    );
  }

  handleFileSelect(event) {
    this.hideAllContainers();
    this.spinnerContainerTarget.classList.remove("hidden");

    Array.from(event.target.files).forEach((file) => {
      this.uploadFile(file);
    });
  }

  uploadFile(file) {
    this.fileNameTarget.textContent = file.name;

    const url = this.inputTarget.dataset.directUploadUrl;
    const upload = new DirectUpload(file, url, this);

    upload.create((error, blob) => {
      if (error) {
        console.error(error);
        this.showError("Upload failed. Please try again.");
      } else {
        this.hiddenInput().value = blob.signed_id;
        this.showSuccess("Upload completed successfully!");
      }
      this.hideAllContainers();
    });
  }

  directUploadWillStoreFileWithXHR(xhr) {
    xhr.upload.addEventListener("progress", (event) =>
      this.updateProgress(event),
    );
  }

  updateProgress(event) {
    if (this.spinnerContainerTarget.classList.contains("hidden") === false) {
      this.hideAllContainers();
      this.progressContainerTarget.classList.remove("hidden");
    }

    const progress = (event.loaded / event.total) * 100;
    const roundedProgress = Math.round(progress);
    this.progressBarTarget.style.width = `${roundedProgress}%`;
    this.progressTextTarget.textContent = `${roundedProgress}%`;
  }

  hideAllContainers() {
    this.spinnerContainerTarget.classList.add("hidden");
    this.progressContainerTarget.classList.add("hidden");
  }

  hiddenInput() {
    if (this.inputTarget.dataset.hiddenInput) {
      return document.querySelector(`#${this.inputTarget.dataset.hiddenInput}`);
    } else {
      const hiddenField = document.createElement("input");
      hiddenField.type = "hidden";
      hiddenField.name = this.inputTarget.name;
      this.inputTarget.parentNode.insertBefore(
        hiddenField,
        this.inputTarget.nextSibling,
      );
      return hiddenField;
    }
  }

  showError(message) {
    // Implement error display logic here
    console.error(message);
    // For example, you could use a toast notification library or update a status element
  }

  showSuccess(message) {
    // Implement success display logic here
    console.log(message);
    // For example, you could use a toast notification library or update a status element
  }
}
