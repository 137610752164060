import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="video-player"
export default class extends Controller {
  connect() {
    this.element.addEventListener("play", () => this.handlePlay());
    this.element.addEventListener("pause", () => this.handlePause());
  }

  togglePlayPause() {
    if (this.element.paused) {
      this.element.play();
    } else {
      this.element.pause();
    }
  }

  handlePlay() {
    // Add any custom behavior when the video starts playing
  }

  handlePause() {
    // Add any custom behavior when the video is paused
  }
}
