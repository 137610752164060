import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="modal"
export default class extends Controller {
  connect() {
    this.element.addEventListener("turbo:submit-end", () => {
      this.close();
    });
  }

  open() {
    this.element.classList.add("modal-open");
  }

  close(event) {
    event?.preventDefault();
    this.element.classList.remove("modal-open");
  }
}
